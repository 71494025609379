export enum CoreRoutePathEnum {
  Dashboard = 'dashboard',
  InboundDocuments = 'inbound-documents',
  Articles = 'articles',
  Clients = 'clients',
  Invoices = 'invoice',
  SalesLedger = 'sales-ledger',
  Vendors = 'vendors',
  PurchaseLedger = 'purchase-ledger',
  Expenses = 'expenses',
  ExpenseCreate = 'expense',
  ExpenseEntity = 'expense/:uuid',
  Persons = 'persons',
  TransactionsBank = 'transactions/bank',
  TransactionsCash = 'transactions/cash',
  TransactionsOther = 'transactions/other',
  Settlements = 'settlements',
  Settlement = 'settlement',
  FinancialEntryCreate = 'entry',
  FinancialEntryEntity = 'entry/:uuid',
  FinancialEntries = 'entries',
  VatReports = 'vat-reports',
  VDReports = 'vd-reports',
  VatReportEntity = 'vat-report/:uuid',
  Ledger = 'ledger',
  TransactionReport = 'transaction-report',
  GeneralLedger = 'general-ledger',
  AnnualReports = 'annual-reports',
  BalanceSheet = 'balance-sheet',
  ProfitAndLossSheet = 'profit-and-loss-sheet',
  StatementOfAccounts = 'statement-of-accounts',
  FixedAssets = 'fixed-assets',
  FixedAssetCreate = 'fixed-asset',
  FixedAssetEntity = 'fixed-asset/:uuid',
  FixedAssetReport = 'fixed-asset-report',
  Depreciation = 'depreciation',
  WriteOff = 'write-off',
  CompanySettings = 'settings',
  VATRates = 'vat-rates',
  PaymentMethods = 'payment-methods',
  OpeningBalances = 'opening-balances',
  Accounts = 'accounts',
}
