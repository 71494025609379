import { Routes } from '@angular/router';
import { importProvidersFrom } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';

import { ArticlesGuard } from '@app/_guards/articles.guard';
import { ClientsGuard } from '@app/_guards/clients.guard';
import { VendorsGuard } from '@app/_guards/vendors.guard';
import { FinancialEntryGuard } from '@app/_guards/financial-entry.guard';
import { FinancialRegistryGuard } from '@app/_guards/financial-registry.guard';
import { SalesLedgerGuard } from '@app/_guards/sales-ledger.guard';
import { PurchaseLedgerGuard } from '@app/_guards/purchase-ledger.guard';
import { DepreciationsGuard } from '@app/_guards/depreciation.guard';
import { FixedAssetGuard } from '@app/fixed-asset/fixed-asset.guard';
import { TransactionsGuard } from '@app/_guards/transaction.guard';
import { ExpenseGuard } from '@app/expense/expenses.guard';
import { ExpensesGuard } from '@app/_guards/expenses.guard';
import { PersonsGuard } from '@app/_guards/persons.guard';
import { BalanceSheetGuard } from '@app/_guards/balance-sheet.guard';
import { PaymentMethodsGuard } from '@app/_guards/payment-methods.guard';
import { VatReportsGuard } from '@app/_guards/vat-reports.guard';
import { VatReportDetailResolver } from '@app/_resolvers/vat-report-detail.resolver';
import { VatReportInfAResolver } from '@app/_resolvers/vat-report-infA.resolver';
import { VatReportInfBResolver } from '@app/_resolvers/vat-report-infB.resolver';
import { CoreRouteBreadcrumbs } from '@app/_containers/core-container/constants';
import { CoreRoutePathEnum } from '@app/_containers/core-container/core-route-path.enum';
import { setCompanyGuard, unsetCompanyGuard } from '@app/_containers/core-container/guards';
import { TrStoreModule } from '@shared/global-modules/tr-store/tr-store.module';
import { LabelEffects, LabelState } from '@domains/label';
import { InvoiceKind } from '@domains/invoice';

import { CoreContainerComponent } from './core-container.component';

export const ROUTES: Routes = [
  {
    path: '',
    component: CoreContainerComponent,
    canActivate: [setCompanyGuard],
    canDeactivate: [unsetCompanyGuard],
    providers: [
      importProvidersFrom(
        TrStoreModule.forFeature(LabelState),
        EffectsModule.forFeature([LabelEffects])
      ),
    ],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: CoreRoutePathEnum.Dashboard,
        data: { breadcrumb: CoreRouteBreadcrumbs.dashboard },
        loadChildren: () =>
          import('@app/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: CoreRoutePathEnum.InboundDocuments,
        data: { breadcrumb: CoreRouteBreadcrumbs['inbound-documents'] },
        loadChildren: () =>
          import('@app/inbound-documents/inbound-documents-v2.module').then(
            (c) => c.InboundDocumentsV2Module
          ),
      },

      // Sales
      {
        path: CoreRoutePathEnum.Articles,
        data: { breadcrumb: CoreRouteBreadcrumbs.articles },
        canActivate: [ArticlesGuard],
        loadChildren: () => import('@app/articles/articles.module').then((m) => m.ArticlesModule),
      },
      {
        path: CoreRoutePathEnum.Clients,
        canActivate: [ClientsGuard],
        data: { breadcrumb: CoreRouteBreadcrumbs.clients },
        loadChildren: () => import('@app/clients/clients.module').then((m) => m.ClientsModule),
      },
      {
        path: CoreRoutePathEnum.Invoices,
        data: { breadcrumb: CoreRouteBreadcrumbs.invoice },
        loadChildren: () => import('@domains/invoice').then((d) => d.ROUTES),
      },
      {
        path: CoreRoutePathEnum.SalesLedger,
        canActivate: [SalesLedgerGuard],
        data: { breadcrumb: CoreRouteBreadcrumbs['sales-ledger'], kind: InvoiceKind.Sale },
        loadChildren: () => import('@domains/ledger').then((m) => m.ROUTES),
      },
      {
        path: CoreRoutePathEnum.Vendors,
        canActivate: [VendorsGuard],
        data: { breadcrumb: CoreRouteBreadcrumbs.vendors },
        loadChildren: () => import('@app/vendors/vendors.module').then((m) => m.VendorsModule),
      },
      {
        path: CoreRoutePathEnum.PurchaseLedger,
        canActivate: [PurchaseLedgerGuard],
        data: { breadcrumb: CoreRouteBreadcrumbs['purchase-ledger'], kind: InvoiceKind.Purchase },
        loadChildren: () => import('@domains/ledger').then((m) => m.ROUTES),
      },

      // Expenses
      {
        path: CoreRoutePathEnum.ExpenseCreate,
        data: { breadcrumb: CoreRouteBreadcrumbs.expense },
        loadChildren: () => import('@app/expense/expense.module').then((m) => m.ExpenseModule),
      },
      {
        path: CoreRoutePathEnum.ExpenseEntity,
        canActivate: [ExpenseGuard],
        data: { breadcrumb: CoreRouteBreadcrumbs['expense/:uuid'] },
        loadChildren: () => import('@app/expense/expense.module').then((m) => m.ExpenseModule),
      },
      {
        path: CoreRoutePathEnum.Expenses,
        canActivate: [ExpensesGuard],
        data: { breadcrumb: CoreRouteBreadcrumbs.expenses },
        loadChildren: () => import('@app/expenses/expenses.module').then((m) => m.ExpensesModule),
      },
      {
        path: CoreRoutePathEnum.Persons,
        canActivate: [PersonsGuard],
        data: { breadcrumb: CoreRouteBreadcrumbs.persons },
        loadChildren: () => import('@app/persons/persons.module').then((m) => m.PersonsModule),
      },

      // Transactions
      {
        path: CoreRoutePathEnum.TransactionsBank,
        canActivate: [TransactionsGuard],
        data: { breadcrumb: CoreRouteBreadcrumbs['transactions/bank'] },
        loadChildren: () =>
          import('@app/transactions/bank-transaction/bank-transaction.module').then(
            (m) => m.BankTransactionModule
          ),
      },
      {
        path: CoreRoutePathEnum.TransactionsCash,
        canActivate: [TransactionsGuard],
        data: { breadcrumb: CoreRouteBreadcrumbs['transactions/cash'] },
        loadChildren: () =>
          import('@app/transactions/cash-transaction/cash-transaction.module').then(
            (m) => m.CashTransactionModule
          ),
      },
      {
        path: CoreRoutePathEnum.TransactionsOther,
        canActivate: [TransactionsGuard],
        data: { breadcrumb: CoreRouteBreadcrumbs['transactions/other'] },
        loadChildren: () =>
          import('@app/transactions/other-transaction/other-transaction.module').then(
            (m) => m.OtherTransactionModule
          ),
      },
      {
        path: CoreRoutePathEnum.Settlements,
        data: { breadcrumb: CoreRouteBreadcrumbs.settlements },
        loadChildren: () => import('@domains/settlement').then((c) => c.ROUTES),
      },

      // Finance
      {
        path: CoreRoutePathEnum.FinancialEntryCreate,
        data: { breadcrumb: CoreRouteBreadcrumbs.entry },
        loadChildren: () =>
          import('@app/financial-entry/financial-entry.module').then((m) => m.FinancialEntryModule),
      },
      {
        path: CoreRoutePathEnum.FinancialEntryEntity,
        canActivate: [FinancialEntryGuard],
        data: { breadcrumb: CoreRouteBreadcrumbs['entry/:uuid'] },
        loadChildren: () =>
          import('@app/financial-entry/financial-entry.module').then((m) => m.FinancialEntryModule),
      },
      {
        path: CoreRoutePathEnum.FinancialEntries,
        canActivate: [FinancialRegistryGuard],
        data: { breadcrumb: CoreRouteBreadcrumbs.entries },
        loadChildren: () =>
          import('@app/financial-registry/financial-registry.module').then(
            (m) => m.FinancialRegistryModule
          ),
      },

      // Reports
      {
        path: CoreRoutePathEnum.VatReports,
        canActivate: [VatReportsGuard],
        data: { breadcrumb: CoreRouteBreadcrumbs['vat-reports'] },
        loadChildren: () =>
          import('@app/vat-report/reports/vat-reports.module').then((m) => m.VatReportsModule),
      },
      {
        path: CoreRoutePathEnum.VDReports,
        data: { breadcrumb: CoreRouteBreadcrumbs['vd-reports'] },
        loadChildren: () => import('@app/vd-reports').then((c) => c.ROUTES),
      },
      {
        path: CoreRoutePathEnum.VatReportEntity,
        data: { breadcrumb: CoreRouteBreadcrumbs['vat-report/:uuid'] },
        loadChildren: () =>
          import('@app/vat-report/report-detail/report-detail.module').then(
            (m) => m.ReportDetailModule
          ),
        resolve: {
          vatReport: VatReportDetailResolver,
          infA: VatReportInfAResolver,
          infB: VatReportInfBResolver,
        },
      },
      {
        path: CoreRoutePathEnum.Ledger,
        data: { breadcrumb: CoreRouteBreadcrumbs.ledger },
        loadChildren: () => import('@domains/ledger').then((c) => c.ROUTES),
      },
      {
        path: CoreRoutePathEnum.TransactionReport,
        data: { breadcrumb: CoreRouteBreadcrumbs['transaction-report'] },
        loadChildren: () => import('@domains/transaction-report').then((c) => c.ROUTES),
      },
      {
        path: CoreRoutePathEnum.GeneralLedger,
        data: { breadcrumb: CoreRouteBreadcrumbs['general-ledger'] },
        loadChildren: () =>
          import('@app/general-ledger/general-ledger.module').then((m) => m.GeneralLedgerModule),
      },
      {
        path: CoreRoutePathEnum.AnnualReports,
        data: { breadcrumb: CoreRouteBreadcrumbs['annual-reports'] },
        loadChildren: () =>
          import('@domains/reports/features/local-annual-reports').then((c) => c.ROUTES),
      },
      {
        path: CoreRoutePathEnum.BalanceSheet,
        canActivate: [BalanceSheetGuard],
        data: { breadcrumb: CoreRouteBreadcrumbs['balance-sheet'] },
        loadChildren: () =>
          import('@app/balance-sheet/balance-sheet.module').then((m) => m.BalanceSheetModule),
      },
      {
        path: CoreRoutePathEnum.ProfitAndLossSheet,
        data: { breadcrumb: CoreRouteBreadcrumbs['profit-and-loss-sheet'] },
        loadChildren: () =>
          import('@domains/profit-and-loss/features/profit-and-loss-sheet').then((c) => c.ROUTES),
      },
      {
        path: CoreRoutePathEnum.StatementOfAccounts,
        data: { breadcrumb: CoreRouteBreadcrumbs['statement-of-accounts'] },
        loadChildren: () =>
          import('@domains/account/features/statement-of-accounts').then((c) => c.ROUTES),
      },

      // Fixed Assets
      {
        path: CoreRoutePathEnum.FixedAssets,
        data: { breadcrumb: CoreRouteBreadcrumbs['fixed-assets'] },
        loadChildren: () =>
          import('@app/fixed-assets/fixed-assets.module').then((m) => m.FixedAssetsModule),
      },
      {
        path: CoreRoutePathEnum.FixedAssetCreate,
        data: { breadcrumb: CoreRouteBreadcrumbs['fixed-asset'] },
        loadChildren: () =>
          import('@app/fixed-asset/fixed-asset.module').then((m) => m.FixedAssetModule),
      },
      {
        path: CoreRoutePathEnum.FixedAssetEntity,
        canActivate: [FixedAssetGuard],
        data: { breadcrumb: CoreRouteBreadcrumbs['fixed-asset/:uuid'] },
        loadChildren: () =>
          import('@app/fixed-asset/fixed-asset.module').then((m) => m.FixedAssetModule),
      },
      {
        path: CoreRoutePathEnum.Depreciation,
        canActivate: [DepreciationsGuard],
        data: { breadcrumb: CoreRouteBreadcrumbs.depreciation },
        loadChildren: () =>
          import('@app/depreciation/depreciation.module').then((m) => m.DepreciationModule),
      },
      {
        path: CoreRoutePathEnum.WriteOff,
        data: { breadcrumb: CoreRouteBreadcrumbs['write-off'] },
        loadChildren: () =>
          import('@app/write-offs/write-off.module').then((m) => m.WriteOffModule),
      },
      {
        path: CoreRoutePathEnum.FixedAssetReport,
        data: { breadcrumb: CoreRouteBreadcrumbs['fixed-asset-report'] },
        loadChildren: () =>
          import('@app/fixed-asset-report/fixed-asset-report.module').then(
            (m) => m.FixedAssetReportModule
          ),
      },

      // Settings
      {
        path: CoreRoutePathEnum.CompanySettings,
        data: { breadcrumb: CoreRouteBreadcrumbs.settings },
        loadChildren: () => import('@pages/company').then((r) => r.ROUTES),
      },
      {
        path: CoreRoutePathEnum.VATRates,
        data: { readonly: true, breadcrumb: CoreRouteBreadcrumbs['vat-rates'] },
        loadChildren: () => import('@domains/vat/features/vat-rates').then((c) => c.ROUTES),
      },
      {
        path: CoreRoutePathEnum.Accounts,
        data: { breadcrumb: CoreRouteBreadcrumbs.accounts },
        loadChildren: () => import('@domains/account').then((r) => r.ROUTES),
      },
      {
        path: CoreRoutePathEnum.PaymentMethods,
        canActivate: [PaymentMethodsGuard],
        data: { breadcrumb: CoreRouteBreadcrumbs['payment-methods'] },
        loadChildren: () =>
          import('@app/payment-methods/payment-methods.module').then((m) => m.PaymentMethodsModule),
      },
      {
        path: `${CoreRoutePathEnum.OpeningBalances}`,
        data: { breadcrumb: CoreRouteBreadcrumbs['opening-balances'] },
        loadChildren: () => import('@domains/opening').then((d) => d.ROUTES),
      },
    ],
  },
];
